/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app) => {
		// app.dump('services.js');

		/*
		|
		| Constants
		|-----------
		*/
		const
			$anchorsWrapper = $('.services-anchors'),
			$anchorItem = $('.services-anchors .item'),
			$servicesContent = $(".servicesContent")
			;

		$anchorItem.on("click", function (e) {
			e.preventDefault();
			$anchorItem.removeClass("active");
			$(this).addClass("active");

			let sectionID = $(this).data("id");
			$servicesContent.fadeOut();
			$("#servicesContent-" + sectionID).fadeIn();

			// gsap.to(window, 1, {
			// 	scrollTo: { y: $("#servicesContent-" + sectionID).offset().top + 200 },
			// 	ease: "power4.out",
			// });

		})

	}
}

