/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
// require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper, { Navigation, Pagination, Autoplay, EffectFade, Manipulation } from 'swiper';
import gsap from "gsap";
import CustomEase from "@lib/gsap-pro/CustomEase";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
// import Scrollbar from 'smooth-scrollbar';



gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTo);

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Manipulation]);

/*
|
| Importing Components
|-----------------------
*/
// import CookieManager from '@components/cookie-manager';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import MobileDetect from '@components/mobile-detect.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import home from '@pages/home.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import about from '@pages/about.js';
import contact from '@pages/contact.js';
import secteurs from '@pages/secteurs.js';
import product from '@pages/product.js';
import services from '@pages/services.js';
import ressources from '@pages/ressources.js';
import landing from '@pages/landing.js';
/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, ScrollTrigger, Menu, Kira, CustomEase]
  },
  {
    'file': main,
    'dependencies': [app, Swiper]
  },
  {
    'file': news,
    'dependencies': [app, gsap],
    'resolve': '#page-news-archive'
  },
  {
    'file': ressources,
    'dependencies': [app, gsap],
    'resolve': '#page-ressources'
  },
  {
    'file': home,
    'dependencies': [app, Swiper, gsap, MobileDetect],
    'resolve': '#page-home'
  },
  {
    'file': secteurs,
    'dependencies': [app, Swiper],
    'resolve': '.single-secteurs'
  },
  {
    'file': about,
    'dependencies': [app, Swiper],
    'resolve': '#page-about'
  },
  {
    'file': contact,
    'dependencies': [app],
    'resolve': '#page-rejoindre'
  },
  {
    'file': product,
    'dependencies': [app, Swiper],
    'resolve': '#page-product-single'
  },
  {
    'file': services,
    'dependencies': [app, gsap],
    'resolve': '#page-services'
  },
  {
    'file': landing,
    'dependencies': [app, gsap],
    'resolve': '#single-landing'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

