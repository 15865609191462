/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, MaterializeForm, customGoogleMap) => {


        $('input[type="file"]').on("change", function () {
            // console.log('Selected file: ' + this.value);
            $(this).next('.gform_fileupload_rules').text(this.value).fadeIn()
            // this.closest(".gform_fileupload_rules").fadeIn()
        });


        /*
        |
        | Materialize form
        |-------------------
        |
        */
        // new MaterializeForm($contactForm, {
        //     selectors: {
        //         group: '.gfield',
        //         label: '.gfield_label',
        //         input: '.ginput_container_text input, .ginput_container_email input'
        //     },
        //     labelEffect: {
        //         duration: 0.3,
        //         ease: Power2.easeOut
        //     }
        // });
    }
}
