/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
            ;


        /*
        |
        | On anchor click scroll animation
        |-----------------
        */
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            // window.scrollTo({ top: $($.attr(this, 'href')).offset().top - 50, behavior: 'smooth' });
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 50,
            }, 1000);
        });

        // /*
        // |
        // | Cookie Manager
        // |-----------------
        // */
        // new CookieManager($cookieBanner, {
        //     name: 'adveris_cookie_use',
        //     duration: 30,
        //     buttons: {
        //         accept: $cookieAccept,
        //         refuse: $cookieRefuse,
        //         close: $cookieClose
        //     },
        //     onAccept: () => {
        //         console.log('accpeted')
        //     },
        //     onRefuse: () => {
        //         console.log('refused')
        //     }
        // });

        // $body.on('loaderEnd', () => console.log('ended'))



        /*
        |
        | Alert info
        |--------------
        */

        new Swiper('.alert-slider', {
            loop: false,
            spaceBetween: 0,
            slidesPerView: 1,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            speed: 800,
            navigation: {
                nextEl: '.navigation .navigation-right',
                prevEl: '.navigation .navigation-left',
            },

        });

        /*
        |
        | Numbers
        |------------
        */
        var numbersSection = document.querySelector('.numbers');
        var hasEntered = false;

        if (numbersSection) {
            window.addEventListener('scroll', (e) => {
                var shouldAnimate = (window.scrollY + window.innerHeight) >= numbersSection.offsetTop;

                if (shouldAnimate && !hasEntered) {
                    hasEntered = true;

                    $('.numbers .number').each(function () {
                        $(this).prop('data-n', 0).animate({
                            Counter: $(this).text()
                        }, {
                            duration: 2000,
                            easing: 'swing',
                            step: function (now) {
                                $(this).text(Math.ceil(now));
                            }
                        });
                    });

                }
            });
        }

        /*
        |
        | Témoignages
        |--------------
        */

        new Swiper('.testimonials-slider', {
            loop: false,
            spaceBetween: 32,
            slidesPerView: 1,

            breakpoints: {
                1024: {
                    slidesPerView: 2.1,
                }
            },
            pagination: {
                el: '.swiper-pagination',
            },
        });

        /*
        |
        | Normes
        |--------------
        */

        new Swiper('.normes-slider', {
            spaceBetween: 32,
            slidesPerView: 1,

            breakpoints: {
                768: {
                    slidesPerView: 1.5,
                },
                1024: {
                    slidesPerView: 2.1,
                },
                1200: {
                    slidesPerView: 3,
                }
            },

            navigation: {
                nextEl: '.navigation .navigation-right',
                prevEl: '.navigation .navigation-left',
            },
        });
    }
}
