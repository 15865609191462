/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap) => {

		/*
		|
		| Constants
		|-----------
		*/
		// const
		// 	$bannerItems = $('.swiper-banner .item-slide'),
		// 	$homeBanner = $('.home-banner'),
		// 	$homeBannerBg = $('.home-banner-bg'),
		// 	$homeBannerContentTitle = $('.home-banner-content .item-title'),
		// 	$homeBannerContentText = $('.home-banner-content .item-text')
		// 	;




		/*
		|
		| Sections
		|--------------
		*/
		$(".single-landing-sections .sections-item").on("click", function () {
			let index = $(this).data("index");
			// console.log(index)
			$(this).addClass("active");
			$('.single-landing-sections .sections-item').not(this).removeClass('active');

			$('.single-landing-sections .sections-image-wrapper .sections-image').removeClass('active');
			$('.single-landing-sections .sections-image-wrapper .sections-image:nth-child(' + index + ')').addClass('active');
		});


	}
}

