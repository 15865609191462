/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, gsap, MobileDetect) => {
		// app.dump('home.js');
		const device = new MobileDetect();


		/*
		|
		| Constants
		|-----------
		*/
		const
			$bannerItems = $('.swiper-banner .item-slide'),
			$homeBanner = $('.home-banner'),
			$homeBannerBg = $('.home-banner-bg'),
			$homeBannerContentTitle = $('.home-banner-content .item-title'),
			$homeBannerContentText = $('.home-banner-content .item-text')
			;

		/*
		|
		| Banner animation on load
		|--------------
		*/
		window.addEventListener('load', (e) => {

			//mobius
			let mobiusTl = gsap.timeline({});
			mobiusTl.to(".home-banner .mobius", {
				duration: 2,
				"clip-path": "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
				"fill": "white",
				// ease: "expo.out"
			});
			mobiusTl.to(".home-banner .mobius svg path", {
				duration: 1,
				"fill": "white",
			});
		});


		/*
		|
		| Banner Swiper
		|--------------
		*/
		let bannerOpts = {
			loop: false,
			spaceBetween: 0,
			slidesPerView: 1,
			// speed: 3000,
			autoplay: {
				delay: 8000,
				pauseOnMouseEnter: true
			},

			// effect: 'fade',
			// fadeEffect: {
			// 	crossFade: true
			// },

			breakpoints: {
				// 768: {
				// 	slidesPerView: 2,
				// },
				// 1024: {
				// 	slidesPerView: 4,
				// },
				1200: {
					slidesPerView: 8,
				},
			},

			navigation: {
				nextEl: '.home-banner-bottom .button-next',
				prevEl: '.home-banner-bottom .button-prev',
			},

			on: {
				init: function () {

				},
			},
		};



		if (!device.isMobile()) {
			bannerOpts.loop = "true";
		}

		const bannerSwiper = new Swiper('.swiper-banner', bannerOpts);
		bannerSwiper.autoplay.stop()

		window.addEventListener("DOMContentLoaded", (event) => {
			if (!device.isMobile()) {
				setTimeout(function () {
					bannerSwiper.autoplay.start();
				}, 4000);
			}
		});




		if (device.isMobile()) {
			bannerSwiper.prependSlide(`
			<div class="swiper-slide" >
				<div class="ta-c c-blue tt-u fs-xs fw-600 item-slide py-sm"
					data-img="${document.querySelector(".home-banner .defaut").dataset.img}" data-title="${document.querySelector(".home-banner .defaut").dataset.title}" data-text="${document.querySelector(".home-banner .defaut").dataset.text}">
					${document.querySelector(".home-banner .defaut").dataset.label}
				</div>
			</div>
			`);
			bannerSwiper.slideTo(0);
		}

		bannerSwiper.on('slideChange', function (e) {



			let loopIndex = $('.swiper-slide-active').data("swiper-slide-index");
			// console.log(loopIndex)

			if (typeof loopIndex !== "undefined") {
				// console.log("loopIndex : " + loopIndex)
				let currentTitle = $(".swiper-slide[data-swiper-slide-index='" + loopIndex + "'] .item-slide").data("title");
				let currentText = $(".swiper-slide[data-swiper-slide-index='" + loopIndex + "'] .item-slide").data("text");
				let currentImg = $(".swiper-slide[data-swiper-slide-index='" + loopIndex + "'] .item-slide").data("img");

				getData(loopIndex, currentTitle, currentText, currentImg);
			}
			else {
				let index = bannerSwiper.activeIndex;
				// console.log("index : " + index)

				let currentTitle = $(".swiper-slide[data-slide='" + index + "'] .item-slide").data("title");
				let currentText = $(".swiper-slide[data-slide='" + index + "'] .item-slide").data("text");
				let currentImg = $(".swiper-slide[data-slide='" + index + "'] .item-slide").data("img");


				getData(index, currentTitle, currentText, currentImg);
			}


			function getData(index, title, text, img) {
				// console.log("index : " + index)
				//Disable loop transform
				if (!device.isMobile()) {
					gsap.to(".swiper-banner .swiper-wrapper", { x: -1380, y: 0, z: 0 })
				}



				changeSlide(img, title, text)
			}

		});




		/*
		|
		| Banner Slides
		|--------------
		*/



		//On hover
		$bannerItems.mouseover(function (e) {
			// console.log("hover")
			changeSlide($(e.target).data("img"), $(e.target).data("title"), $(e.target).data("text"));
		});

		$bannerItems.mouseout(function (e) {
			// console.log("out")
			bannerSwiper.autoplay.start();
		});


		function changeSlide(img, title, text) {
			$homeBannerBg.fadeOut(function () {
				$(this).attr("src", img).fadeIn(800)
			});

			$homeBannerContentTitle.fadeOut(function () {
				$(this).text(title).fadeIn();
			});

			$homeBannerContentText.fadeOut(function () {
				$(this).text(text).fadeIn();
			});
		}



		/*
		|
		| Nos produits
		|--------------
		*/

		new Swiper('.home-products-slider', {
			loop: false,
			spaceBetween: 32,
			slidesPerView: 1.4,

			breakpoints: {
				768: {
					slidesPerView: 1.8,
				},
				1024: {
					slidesPerView: 2.6,
				},
				1224: {
					slidesPerView: 4,
				},
			},

			// navigation: {
			// 	nextEl: '.swiper-button-next',
			// 	prevEl: '.swiper-button-prev',
			// },
		});








	}
}

