/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {
		// app.dump('about.js');

		/*
		|
		| Constants
		|-----------
		*/
		// const
		// 	$loadedPostsContainer = $('.loaded-posts')
		// 	;


		/*
		|
		| Dates
		|--------------
		*/

		new Swiper('.dates-slider', {
			loop: false,
			spaceBetween: 15,
			slidesPerView: 1,
			breakpoints: {
				768: {
					slidesPerView: 2.2,
				},
				1024: {
					slidesPerView: 3.2,
				},
				1200: {
					slidesPerView: 4,
				}
			},

			navigation: {
				nextEl: '.navigation .navigation-right',
				prevEl: '.navigation .navigation-left',
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
		});

	}

}

