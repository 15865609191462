/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, gsap) => {
		// app.dump('product.js');

		/*
		|
		| Constants
		|-----------
		*/
		// const
		// 	$bannerItems = $('.swiper-banner .item-slide'),
		// 	$homeBanner = $('.home-banner'),
		// 	$homeBannerBg = $('.home-banner-bg'),
		// 	$homeBannerContentTitle = $('.home-banner-content .item-title'),
		// 	$homeBannerContentText = $('.home-banner-content .item-text')
		// 	;




		/*
		|
		| Problématiques
		|--------------
		*/

		new Swiper('.problems-slider', {
			spaceBetween: 32,
			slidesPerView: 1,
			centerInsufficientSlides: true,
			// autoHeight: true,


			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
				},
			},

			navigation: {
				nextEl: '.navigation .navigation-right',
				prevEl: '.navigation .navigation-left',
			},
			on: {
				init: function (e) {
					if (e.slides.length < 3) {

					}
				},
			}
		});


		/*
		|
		| Presentation
		|--------------
		*/

		new Swiper('.presentation-slider', {
			spaceBetween: 32,
			slidesPerView: 1,
			// autoHeight: true,
			// loop: true,
			speed: 1000,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},

			navigation: {
				nextEl: '.navigation .navigation-right',
				prevEl: '.navigation .navigation-left',
			},
			pagination: {
				el: '.swiper-pagination',
			},
		});


		/*
		|
		| Problématiques
		|--------------
		*/

		new Swiper('.advantages-slider', {
			spaceBetween: 32,
			slidesPerView: 1,
			centerInsufficientSlides: true,
			// autoHeight: true,

			breakpoints: {
				768: {
					slidesPerView: 2.4,
				},
				1200: {
					slidesPerView: 3,
				}
			},

			navigation: {
				nextEl: '.navigation .navigation-right',
				prevEl: '.navigation .navigation-left',
			},
		});



		/*
		|
		| Related
		|--------------
		*/

		new Swiper('.products-slider', {
			spaceBetween: 32,
			slidesPerView: 1,

			breakpoints: {
				768: {
					slidesPerView: 1.5,
				},
				1024: {
					slidesPerView: 2.1,
				},
				1300: {
					slidesPerView: 3,
				}
			},

			navigation: {
				nextEl: '.navigation .navigation-right',
				prevEl: '.navigation .navigation-left',
			},
		});





	}
}

