/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, gsap) => {
		// app.dump('secteurs.js');

		/*
		|
		| Constants
		|-----------
		*/
		// const
		// 	$bannerItems = $('.swiper-banner .item-slide'),
		// 	$homeBanner = $('.home-banner'),
		// 	$homeBannerBg = $('.home-banner-bg'),
		// 	$homeBannerContentTitle = $('.home-banner-content .item-title'),
		// 	$homeBannerContentText = $('.home-banner-content .item-text')
		// 	;




		/*
		|
		| Banner Swiper
		|--------------
		*/

		const swiperBanner = new Swiper('.carousselWithBlocs', {
			spaceBetween: 32,
			slidesPerView: 1,
			// autoHeight: true,

			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1200: {
					slidesPerView: 3,
				},
			},

			navigation: {
				nextEl: '.navigation .navigation-right',
				prevEl: '.navigation .navigation-left',
			},
		});




	}
}

